const variantName = 'navigationV3'

export default {
  [variantName]: {
    // ?============================
    // ?======== Container =========
    // ?============================

    container: {
      position: 'static',
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      height: ['', '', '', '110px'],
      padding: ['1rem 0.75rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: 'white',
      borderTop: '3px solid',
      borderColor: 'primary'
    },

    containerScrolled: {
      variant: `${variantName}.container`,
      height: ['', '', '', '110px'],
      position: 'fixed',
      padding: ['1rem 0.75rem', '', '', '0px']
    },

    // ?============================
    // ?=======  Nav Logos =========
    // ?============================

    logo: {
      position: ['absolute'],
      top: ['0rem', '', '', ''],
      left: ['0rem', '', '', ''],
      alignSelf: 'top',
      zIndex: '2000',
      img: {
        maxHeight: '110px',
        maxWidth: '200px',
        padding: '10px'
      },
      display: 'none!important'
    },
    logoScrolled: {
      variant: `${variantName}.logo`,
      maxHeight: ['100%', '110px'],
      textAlign: ['center', '', '', 'left'],
      left: ['0%', '', '', '0px'],
      top: ['0px', '', '', '0px'],
      height: ['100%'],
      display: ['', '', '', 'inline'],
      img: {
        height: ['100%'],
        maxHeight: ['70px', '', '', 'unset'],
        padding: ['10px']
      },
      display: 'none!important'
    },

    // ?============================
    // ?=======  Hamburger =========
    // ?============================

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },

    // ?============================
    // ?========  Nav Menu =========
    // ?============================

    navMenu: {
      position: ['absolute', 'absolute', 'absolute', 'unset'],
      margin: '0px',
      boxSizing: 'border-box',
      justifyContent: ['center', '', '', 'center'],
      height: ['100vh', '100vh', '100vh', 'unset'],
      backgroundColor: 'white',
      backgroundImage: [
        "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')",
        '',
        '',
        'none'
      ],
      top: '0px',
      zIndex: '1000',
      width: ['100%', '100%', '100%', '100%'],
      minWidth: '250px',
      left: -'120%',
      transition: 'all ease-in-out 0.5s',
      padding: ['3rem 2rem 2rem', '', '', '0.5rem'],
      alignItems: ['flex-start', '', '', 'center'],
      display: 'flex',
      flexDirection: ['column', 'column', 'column', 'row'],
      // overflow: 'hidden',
      listStyleType: 'none',
      '> .our-culture': {
        display: 'none'
      },

      '> .contact-us': {
        display: 'none'
      },
      '> .gallery': {
        display: 'none'
      },
      '> .events': {
        display: 'none'
      }
    },
    navItem: {
      zIndex: '100',
      margin: ['0.75rem'],
      listStyleType: 'none',
      a: {
        fontSize: ['1.1rem', '1.2rem', '', '1rem', '1rem'],
        textTransform: 'uppercase',
        fontWeight: 'heading',
        letterSpacing: '-1px',
        textDecoration: 'none',
        opacity: '0.7',
        transition: 'all ease-in-out 0.1s',
        ':hover': {
          fontSize: ['1.1rem', '1.2rem', '', '1.2rem', '1.2rem'],
          borderBottom: '4px solid',
          '::before': {
            content: "'⭐'",
            marginRight: '0.25rem'
          }
        }
      }
    },

    // ?==========================
    // ?====  Dropdown Styles ====
    // ?==========================

    navItemDropdown: {
      alignItems: 'inherit',
      fontFamily: 'inherit',
      justifyContent: 'inherit',
      position: 'relative'
    },

    subNavItemDropdown: {
      variant: `${variantName}.navItem`,
      padding: '0.5rem',
      margin: ['0rem', '0rem', '0rem', '0rem'],
      whiteSpace: 'nowrap'
    },

    navItemDropdownMenu: {
      padding: '0.5rem',
      textShadow: '0px 0px 30px rgb(122 122 122 / 79%)',
      position: ['', '', '', 'absolute'],
      display: 'flex',
      alignItems: 'inherit',
      justifyContent: 'inherit',
      flexDirection: 'column',
      width: 'fit-content',
      backgroundColor: ['', '', '', 'light'],
      '.navItem': {
        a: {
          '::before': { content: 'none' },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        ':hover': {
          color: 'primary',
          opacity: '0.8'
        }
      }
    },

    // ?============================
    // ?====  Extra Nav Widgets ====
    // ?============================

    socialIconsContainer: {
      margin: ['1rem 0.75rem', '', '', '0rem 0rem 0rem 0.5rem'],
      padding: ['1.75rem 0.75rem 0.75rem 0rem', '', '', '0.5rem 0rem 0.5rem 0.5rem'],
      borderTop: ['1px solid black', '', '', 'none'],
      borderLeft: ['', '', '', '1px solid grey'],
      a: {
        backgroundColor: 'black',
        border: 'solid 1px black',
        borderRadius: '500px',
        padding: '0.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '0.35rem',
        transition: 'all ease-in-out 0.1s',
        ':hover': {
          backgroundColor: 'primary',
          transform: 'scale(1.2)',
          path: {
            fill: 'white'
          }
        }
      },
      svg: {
        width: '30px',
        height: '30px',
        path: {
          fill: 'white'
        }
      }
    },
    phoneContainer: {
      variant: 'buttons.primary',
      margin: ['0.5rem 0rem 0rem', '', '', '0rem 0rem 0rem 0.5rem'],
      fontSize: ['0.9rem', '', '', '0.9rem'],
      padding: ['0.75rem', '', '', '0.5rem', '', '1rem 2rem']
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', '', '', 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', '', '', 'unset'],
      zIndex: '2000'
    }
  }
}
