export default {
  footer: {
    padding: ['0.5rem 0.5rem 3rem', '', '', '1rem 3.5rem'],
    backgroundColor: 'white',
    borderTop: '1px solid black',
    '.columnLogo': {
      display: 'none'
    },
    '.columnSocial': {
      'svg path': {
        fill: 'text'
      }
    },
    '.columnNav a': {
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.row': {
      justifyContent: 'center'
    },
    '.row .column': {
      alignItems: ['center', 'center', 'center', 'center', 'center'],
      margin: ['0rem', '', '1rem'],
      textAlign: 'center',
      justifyContent: ['center', 'center', 'center', 'center'],
      '.contactDetails-container': {
        margin: '0rem'
      },
      '.multiButtonContainer a': {
        margin: ['0.5rem', '', '', '0.25rem'],
        padding: '0.5rem',
        ':hover': {
          color: 'white',
          backgroundColor: 'primary'
        }
      }
    },
    '.footerSectionHeading': {
      fontSize: '1.5rem',
      color: 'primary',
      filter: 'brightness(1.15)'
    },

    '.column .contactDetails-container .text': {
      margin: '0.5rem 0rem 1rem',
      fontSize: '1rem'
    }
  },

  // ?  =========  Homepage  ===========

  homepageHero: {
    padding: ['3rem 1rem', '', '8rem 1rem'],
    height: 'auto',
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/sites/milkcraft/milkcraft_elements_rays.png')",
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    img: {
      width: ['100%', '', '80%']
    },
    h1: {
      marginTop: ['1rem', '', '3rem'],
      fontFamily: 'display2',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: ['calc(0.6rem + 3.5vw)'],
      textAlign: 'center'
    }
    // height: ['100vh', '', '', '100vh'],
    // overflow: 'hidden',
    // color: 'light',
    // '.logoHero ': {
    //   maxHeight: ['200px', '', '', '300px'],
    // },
    // '.title': {
    //   letterSpacing: '3px',
    //   fontSize: ['1.6rem', '', '2rem', '3.5rem'],
    //   textShadow: '1px 1px black',
    //   fontFamily: 'display2',
    //   textTransform: 'capitalize',
    //   color: 'black',
    // },
    // '.subtitle': {
    //   fontFamily: 'Source Sans Pro Italic, sans-serif !important',
    //   // fontStyle: 'italic',
    //   color: 'white',
    //   fontSize: ['1.25rem', '', '', '2rem'],
    //   backgroundColor: 'rgb(0 0 0 / 67%)',
    //   padding: '1rem',
    //   borderRadius: '200px',
    //   border: 'solid 3px',
    //   borderColor: 'primary',
    // },
    // '.ctaButton': {
    //   fontWeight: 'bold',
    //   ':hover': {
    //     backgroundColor: 'white',
    //     color: 'black',
    //   },
    // },
    // '.slick-slide img': {
    //   height: '100%',
    //   filter: 'brightness(0.9)',
    // },
    // '.hero_content_container': {
    //   color: 'light',
    //   width: '100%',
    //   maxWidth: '1200px',
    //   margin: ['2rem 0rem 0rem', '', '', '1rem 0rem 0rem'],
    // },
    // '.slick-initialized .slick-slide > div': {
    //   height: ['100vh', '', '', '100vh'],
    // },
    // '.slick-arrow': {
    //   display: 'none!important',
    // },
  },

  homepageFeaturedItems: {
    maxWidth: 'unset',
    overflowX: 'hidden',
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1616164524/sites/milkcraft/AdobeStock_313818309.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '.menuSectionTitle': {
      fontSize: ['1.5rem', '1.75rem', '2rem', '3rem'],
      color: 'primary',
      margin: '0.5rem',
      borderBottom: '5px solid',
      width: 'fit-content',
      margin: '0 auto 1rem',
      paddingBottom: '1rem'

      // fontFamily: 'display2',
    },
    '.menuSectionDescription': {
      // display: 'none',
      fontFamily: 'display2',
      fontWeight: 'heading',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
      color: '#e9e9e9',
      // opacity: '0.5',
      '::after': {
        content: "'?'",
        fontSize: '4rem'
      }
    },

    '.menuItemContentContainer': {
      alignItems: 'center'
    },
    '.menuItemInnerContainer': {
      alignItems: 'center'
    },

    '.menuItemImageContainer': {
      borderRadius: '500px',
      overflow: 'hidden',
      height: '300px',
      width: '300px',
      marginBottom: '1rem',
      border: 'solid 5px',
      borderColor: 'primary'
    },

    '.menuItemName': {
      fontFamily: 'heading',
      fontWeight: 'heading',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: '#b61c22',
      fontSize: ['1.25rem', '1.5rem']
    },

    '.menuItemDescription': {
      width: '100%',
      textAlign: 'center',
      color: 'primary'
    }
  },

  homepageSlider: {
    padding: '4rem 0rem 2rem',
    boxShadow: '-1px -19px 10px 2px rgb(0 0 0 / 32%)',
    '.mainTextContainer': {
      '.title': {
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
        color: 'primary'
      },
      '.subtitle': {
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
        opacity: '0.5'
      }
    },
    '.slick-slide img': {},
    '.imageFill': {
      paddingBottom: '75%'
    }
  },

  contentSection1: {
    padding: '8rem 1rem',
    color: 'white',
    textShadow: '0px 3px 8px black',
    '.title': {
      fontSize: ['1.6rem', '', '2.5rem', '2.8rem', '3.5rem'],
      display: 'flex',
      alignItems: 'center',
      order: '1'
    },
    '.subtitle': {
      fontFamily: 'display2',
      textTransform: 'capitalize',
      order: '2',
      fontSize: ['3rem', '', '6rem'],
      margin: '2rem 0rem'
    },
    a: {
      order: '3',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  sideBySide1: {
    justifyContent: 'center',
    '.videoContainer': {
      video: {
        minHeight: ['', '', '60vh']
      }
    },
    '.content': {
      padding: ['1rem', '2rem', '', '4rem', '5rem'],
      width: ['', '', '50%'],
      order: ['', '', '2'],
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1616099469/sites/milkcraft/milkcraft_elements_web_cloud2.png)',
      backgroundSize: '75%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '250% 150%'
    },
    '.title': {
      fontSize: ['3rem', '3.5rem', '4.5rem', '4.5rem', '5rem'],
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'display2',
      textTransform: 'capitalize'
      // '::before': {
      //   content: "'⭐'",
      //   marginRight: '1rem',
      // },
    },
    '.subtitle': {
      color: 'primary',
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '.text': {
      maxWidth: '400px',
      color: 'primary',
      fontSize: ['1rem', '', '1.25rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.lazyload-wrapper': {
      width: ['', '', '35%'],
      padding: ['1rem 2rem'],
      order: ['', '', '1'],
      img: {
        maxWidth: '400px',
        margin: '0 auto',
        display: 'block'
      }
    }
  },

  sideBySideVideo: {
    backgroundColor: '#b61c22',
    '.videoContainer': {
      video: {
        minHeight: ['', '', '60vh']
      },
      iframe: {
        height: ['60vw', '', '50vw']
      }
    },

    '.content': {
      padding: ['2rem 1rem', '2rem', '', '4rem', '5rem'],
      width: ['', '', '50%'],
      color: 'white'
    },
    '.title': {
      fontSize: ['3rem', '3.5rem', '4.5rem', '4.5rem', '5rem'],
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'display2',
      textTransform: 'capitalize'
      // '::before': {
      //   content: "'🚚'",
      //   marginRight: '1rem',
      // },
    },
    '.text': {
      maxWidth: '400px',
      color: '#f4f4f4',
      fontSize: ['1rem', '', '1.25rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  locationsTitleBlock: {
    marginTop: '2rem',
    paddingBottom: '1rem',
    '.title': {
      fontSize: ['1.3rem', '1.75rem', '2rem', '2.5rem'],
      color: 'primary'
    }
  },
  locationBoxes1: {
    '.box': {
      flex: ['', '', '1'],
      height: ['400px', '', '500px'],
      justifyContent: 'center',
      margin: '0.5rem',
      transition: 'all ease-in-out 0.3s',
      backgroundPosition: 'center',
      ':hover': {
        backgroundPosition: 'bottom left',
        '.title': {
          fontSize: '2rem',
          textDecoration: 'underline'
        }
      },
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },
    '.title': {
      transition: 'all ease-in-out 0.3s',
      fontSize: '1.5rem',
      fontWeight: 'normal',
      color: 'white',
      marginBottom: '1rem',
      fontFamily: 'heading',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      widht: '100%',
      '::before': {
        content: "'📍'",
        paddingRight: '1rem'
      }
    },
    '.text': {
      color: 'white'
    },
    '.contactDetails-container': {
      // opacity: '',
      a: {
        ':hover': {
          color: 'lightgrey'
        }
      }
    },

    '.socialIconsContainer': {
      display: 'none'
    }
  },

  locationsTitleBlock2: {
    background:
      'url("https://res.cloudinary.com/gonation/image/upload/v1616693309/sites/milkcraft/MILKCRAFT_bubble_cone_graphic.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '25%',
    backgroundPosition: 'left',
    color: 'black',
    padding: ['3rem 1rem 3rem 5rem', '3rem 1rem 3rem 7rem', '8.5rem 2rem 8.5rem 13rem'],
    '.title': {
      fontSize: '2rem',
      marginBottom: '1rem',
      color: 'primary'
    },
    '.subtitle': {
      fontSize: ['1.2rem', '1.5rem', '2rem'],
      maxWidth: '767px',
      margin: '0 auto',
      color: '#b61c22'
    }
  },

  // ? ====== About page ==========

  aboutsection: {
    backgroundSize: '25%',
    backgroundPosition: ['-100% 50%', '', '-20% 80%'],
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    backgroundColor: '#b61c22',
    '.title': {
      color: 'white',
      fontFamily: 'display2',
      textTransform: 'capitalize',
      borderBottom: 'white 8px dotted',
      borderRadius: '133px',
      padding: '1rem 2rem 2.5rem',
      fontSize: ['2rem', '3rem', '', '4rem']
    },
    '.text': {
      fontWeight: '500',
      fontSize: ['1rem', '', '1.2rem'],
      color: 'white'
    }
  },
  aboutpageSlider: {
    variant: 'customVariants.homepageSlider',
    backgroundColor: '#b61c22',
    '.mainTextContainer .title': {
      color: 'white',
      fontFamily: 'display2',
      textTransform: 'capitalize',
      fontSize: ['2rem', '3rem', '', '4rem']
    }
  },

  sideBySideVideo2: {
    // margin: '1rem 0rem',
    backgroundColor: '#b61c22',
    '.content': {
      padding: ['3rem 1rem', '4rem 2rem', '', '4rem'],
      width: ['', '', '60%'],
      order: ['', '', '2']
    },
    '.title': {
      borderBottom: '3px solid',
      borderColor: 'white',
      paddingBottom: '1rem',
      fontSize: ['1.6rem', '', '2.5rem', '2.8rem', '3.5rem'],
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      fontFamily: 'display2',
      textTransform: 'capitalize',
      color: 'white',
      fontFamily: 'display2',
      textTransform: 'capitalize',
      fontSize: ['2rem', '3rem', '', '4rem']
    },
    '.subtitle': {
      color: 'white',
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '.text': {
      margin: '1rem 0rem',
      textTransform: 'uppercase',
      fontSize: ['1.1rem', '', ''],
      lineHeight: '2',
      fontWeight: 'bold',
      background: 'linear-gradient(to bottom, #f16a85 0%, #8086C1 33%, #8cc240 66%,#808285 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.lazyload-wrapper': {
      width: ['', '', '40%'],
      order: ['', '', '1']
    }
  },

  aboutSideBySide1: {
    backgroundColor: '#b61c22',
    justifyContent: 'center',
    '.content': {
      padding: ['2rem 1rem 1rem', '4rem 2rem 1rem', '', '4rem'],
      width: ['', '', '50%'],
      // backgroundImage:
      //   "url('https://res.cloudinary.com/gonation/image/upload/v1613604552/sites/milkcraft/lines.png')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    '.title': {
      fontSize: ['1.5rem', '', '', '2.5rem'],
      color: 'white',
      fontFamily: 'display2',
      textTransform: 'capitalize',
      fontSize: ['2rem', '3rem', '', '4rem']
    },
    '.subtitle': {
      color: 'primary',
      fontSize: '1.25rem',
      fontWeight: '500'
    },
    '.text': {
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.lazyload-wrapper': {
      width: ['', '', '35%'],
      padding: ['1rem 2rem'],

      img: {
        maxWidth: '400px',
        margin: '0 auto',
        display: 'block'
      }
    }
  },

  menu: {
    maxWidth: 'unset',
    backgroundImage: [
      "url('https://res.cloudinary.com/gonation/image/upload/v1616164524/sites/milkcraft/AdobeStock_313818309.jpg')"
    ],
    // backgroundImage: [
    //   "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')",
    // ],
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',

    '.our-menu > .menuContainer > .menuSectionTitle': {
      display: 'none'
    },

    '.scoops': {
      padding: '2rem 0rem',
      // backgroundImage: [
      //   "url('https://res.cloudinary.com/gonation/image/upload/v1616164524/sites/milkcraft/AdobeStock_313818309.jpg')",
      // ],
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto',
      '.menuItemsContainer': { justifyContent: 'center' },
      '.menuItemContainer, .menuItemContainerImgActive': {
        width: 'calc(33% - 1rem)',
        padding: '0rem',
        flexGrow: '1',
        maxWidth: '250px'
      },
      '.menuSectionDescription': {
        color: 'white'
      },
      '.menuItemInnerContainer': {
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center'
      },
      '.menuItemImageContainer': {
        width: '100%',
        height: 'unset'
      },

      '.menuItemContentContainer, .menuItemNamePriceContainer': {
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'unset',
        margin: 'auto 0 0'
      },
      '.menuPriceContainer': {
        justifyContent: 'center',
        color: 'white'
      },
      '.menuItemPrice': {
        color: 'white'
      },
      '.menuItemImageContainer': { maxWidth: '100%', width: '80%' },
      '.menuItemImg': {
        objectFit: 'contain',
        maxHeight: '100px',
        padding: '0rem',
        borderRadius: '0px',
        border: 'none'
      },
      '.menuItemName': {
        paddingRight: '0rem',
        color: 'white'
      }
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: ['1rem 0.5rem 2rem', '1rem 1rem 2rem', '1rem 1.5rem 2rem'],
      fontSize: ['1.5rem', '', '1.7rem', '2.7rem'],
      color: 'primary',
      borderBottom: '3px dotted',
      borderColor: 'primary'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      margin: '0.5rem 0rem',
      color: '#b61c22'
    },
    '.menuItemDescription': {
      color: 'primary',
      opacity: '0.8',
      textTransform: 'uppercase',
      color: 'white'
    },
    '.menuItemInnerContainer': {
      flexWrap: 'wrap'
    },

    '.menuItemImageContainer': {
      width: ['50%', '', '27%'],
      maxWidth: ['50%', '30%'],
      marginRight: '0.5rem'
    },

    '.menuItemImg': {
      borderRadius: '500px',
      border: 'solid 3px',
      borderColor: 'primary',
      paddingRight: '0rem',
      width: '150px',
      height: '150px'
    },

    '.secondMenuItemImage': {
      width: '70%',
      border: 'dotted 2px',
      borderColor: 'primary',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        // filter: 'brightness(0)',
        maxHeight: '140px',
        padding: '1rem'
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
    display: 'none !important'
  },

  locationBoxes2: {
    variant: 'customVariants.locationBoxes1',
    padding: '4rem 1rem',
    backgroundImage: [
      "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')"
    ]
    // '.title': {
    //   fontSize: '1.5rem',
    //   fontFamily: 'Lato',
    //   fontWeight: 'bold',
    //   color: 'primary',
    //   marginBottom: '1rem',
    // },
    // '.contactDetails-container': {
    //   opacity: '0.7',
    // },
    // '.box': {
    //   // background: 'unset',
    // },
  },

  contactPageHours: {
    padding: '3rem 1rem',
    background: 'black',
    color: 'white',
    '.section': {
      padding: '0rem',
      maxWidth: '767px'
    },
    '.title': {
      marginBottom: '3.5rem'
    },
    '.dayofWeekText ': {
      color: 'primary'
    },
    '.hoursContainer': {
      display: 'flex',
      flexDirection: 'column'
    },
    '.hoursTitle': {
      color: 'white',
      textAlign: 'center',
      marginBottom: '1rem',
      width: '100%',
      fontSize: ['1.5rem', '2rem'],
      fontFamily: 'display2',
      paddingBottom: '1rem',
      borderBottom: '3px dotted',
      borderColor: 'primary'
    },
    '.hoursText': {
      textAlign: 'center',
      width: '100%',
      color: 'primary',
      fontSize: ['1rem', '1.2rem', '1.75rem'],
      lineHeight: '2'
    }
  },

  contactForm: {
    padding: '6rem 1rem',
    backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/v1613604552/sites/milkcraft/lines.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '.inputField, .textField': {
      border: 'none',
      borderBottom: '2px black solid',
      borderRadius: '0px',
      '::placeholder': {
        color: 'grey'
      }
    },
    '.submitBtn': {
      letterSpacing: '2px',
      fontWeight: 'normal'
    }
  },

  getQuoteForm: {
    padding: '3rem 1rem',
    borderTop: '3px solid black',
    '.inputField, .textField': {
      border: 'none',
      borderBottom: '2px black solid',
      borderRadius: '0px',
      '::placeholder': {
        color: 'grey'
      }
    },
    '.title': {
      marginBottom: '2.5rem'
    },
    '.submitBtn ': {
      margin: '1rem auto',
      padding: '1rem 2rem'
    },
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')"
  },

  milkTruckHero: {
    '.videoContainer::before': {
      background: 'none'
    },
    '.title': {
      fontSize: ['1.5rem', '', '2rem']
    },
    '.subtitle': {
      fontSize: ['1rem', '', '1.5rem'],
      opacity: '0.8'
    }
  },

  milkTruckContent: {
    backgroundColor: '#b61c22',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    maxWidth: 'unset',
    '.innerMenuContainer': {
      maxWidth: '1200px',
      margin: '0 auto'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.menuSectionTitle': {
      textAlign: 'left',
      color: 'white',
      margin: ['1rem 0.5rem 1.5rem', '1rem 1rem 1.5rem', '1rem 1.5rem 1.5rem', '1rem 1.5rem 1.5rem']
    },
    '.menuSectionDescription': {
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      margin: ['0rem 0.5rem 1rem', '0rem 1rem 1rem', '0rem 1.5rem 1rem', '0rem 1.5rem 1rem'],
      color: 'white'
    },

    '.menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      color: 'white'
    },
    '.menuItemDescription': {
      color: 'white'
    }
  },

  events: {
    // backgroundImage: [
    //   "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')",
    // ],
    color: 'white',
    backgroundImage: [
      "url('https://res.cloudinary.com/gonation/image/upload/v1616099467/sites/milkcraft/milkcraft_elements_web_cloud.png')"
    ],
    backgroundColor: '#b61c22',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  gallery: {
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    backgroundPosition: 'center',
    backgroundImage:
      "url('https://res.cloudinary.com/gonation/image/upload/v1613407763/sites/milkcraft/white-brick-wall.png')",
    '.albumsCTA': {
      flexGrow: '1',
      border: 'solid 6px',
      borderColor: 'primary',
      borderRadius: '500px'
    },
    '.albumImage': {
      border: 'solid 6px',
      borderColor: 'primary',
      borderRadius: '500px'
    }
  },

  pressContentBoxes: {
    maxWidth: '1200px',
    margin: 'auto',
    justifyContent: 'center',
    '.box': {
      width: ['100%', '48%', '48%', '48%'],
      border: 'solid 3px',
      borderColor: 'primary',
      margin: '1%'
    },
    '.image': {},
    '.title': {
      margin: '1rem 0rem',
      color: 'primary',
      textTransform: 'capitalize',
      fontFamily: 'display2',
      borderBottom: 'dotted 3px',
      borderColor: 'primary',
      fontSize: ['2rem', '2rem', '3rem']
    },
    '.subtitle': {
      fontSize: '1rem',
      lineHeight: '1.75',
      opscity: '0.8'
    },

    '.text': {
      borderBottom: '1px solid',
      width: 'fit-content',
      textTransform: 'uppercase',
      marginTop: '1rem',
      fontWeight: 'bold'
    }
  }
}
