export default {
  primary: {
    color: 'light',
    backgroundColor: 'dark',
    padding: '1.25rem 2rem',
    borderRadius: '160px',
    clipPath: 'polygon(0 5%, 100% 5%, 100% 95%, 0 95%)',
    border: 'none',
    transition: 'all ease-in-out 0.3s',
    fontFamily: 'heading',
    fontWeight: 'normal',
    ':hover': {
      backgroundColor: 'secondary'
    }
  },
  secondary: {
    color: 'light',
    backgroundColor: 'secondary',
    padding: '1.25rem 2rem',
    borderRadius: '160px',
    clipPath: 'polygon(0 5%, 100% 5%, 100% 95%, 0 95%)',
    border: 'none',
    transition: 'all ease-in-out 0.3s',
    ':hover': {
      backgroundColor: 'dark'
    }
  }
}
