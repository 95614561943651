export default {
  heading: 'Ultra, sans-serif',
  body: 'Lato, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'TrendSansW00-Four, sans-serif',
  display2: 'Thirsty Rough Bold',
  googleFonts: ['Ultra', 'Lato', ''],
  customFamilies: ['TrendSansW00-Four', 'Thirsty Rough Bold'],
  customUrls: [
    'https://gonation.biz/fonts/trend-sans-w00-four/trend-sans-w00-four.css',
    'https://gonation.biz/fonts/thirsty-rough-bold/thirsty-rough-bold.css'
  ]
}
